<template>
  <div>
    <div class="box d-flex" style="height: calc(100vh - 160px);">
      <el-menu :default-active="activeRoute" @select="selectPage" >
        <el-menu-item index="CreateEmail">
          <i class="el-icon-message"></i>
          <span slot="title">寫信</span>
        </el-menu-item>
        <el-menu-item index="EmailList">
          <i class="fas fa-clock"></i>
          <span slot="title"> &nbsp;郵件列表</span>
        </el-menu-item>
        <!-- <el-menu-item index="EmailTemplate">
          <i class="fas fa-file-code"></i>
          <span slot="title"> &nbsp;內容樣板</span>
        </el-menu-item> -->
      </el-menu>
      <div class="content">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import mailApi from "@/apis/mail";

export default {
  metaInfo() {
    return {
      title: "Email Compose - Ivy-Way Academy"
    };
  },

  components: {},

  mixins: [],

  props: {},

  data() {
    return {};
  },
  computed: {
    activeRoute(){
      if(this.$route.name == "EditEmail"){
        return "CreateEmail";
      } else {
        return this.$route.name;
      }
    }
  },
  watch: {},

  async mounted() {},

  methods: {
    selectPage(route) {
      this.$router.replace({
        name: route
      });
    }
  }
};
</script>

<style scoped>
.box .content {
  flex: 1;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
</style>
